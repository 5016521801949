import { AvatarModel } from '@Builder/dto/internal/avatar';
import EnvironmentType from '@Builder/dto/internal/env';
import { ActionTypes } from '@Builder/dto/internal/menuAction';
import UserModel from '@Builder/dto/internal/user';
import { useDisclosure } from '@chakra-ui/react';
import { AlertDialog } from '@components/Controllers/BuildableAlertDialogController';
import { createContext, useContext, useEffect, useState } from 'react';

const BuilderContext = createContext(undefined);

function BuilderContextProvider({ children }) {
  const [flow, setFlow] = useState(undefined);
  const [showEditor, setShowEditor] = useState(false);
  const [selectedStep, setSelectedStep] = useState();
  const [elements, setElements] = useState([]); // This data should come from the backend
  const [showSecondStepModal, setShowSecondStepModal] = useState(false);
  const [env, setEnv] = useState(EnvironmentType.DEVELOPMENT);
  const [editable, setEditable] = useState(true); // this should be false
  const [currentLogicFlow, setCurrentLogicFlow] = useState(undefined as string);
  const [alertDialog, setAlertDialog] = useState({});
  const [currentUsersEditing, setCurrentUsersEditing] = useState(
    [] as AvatarModel[]
  );
  //TODO: Use
  const [currentUser, setCurrentUser] = useState({} as UserModel);

  const [editingNodes, setEditingNodes] = useState({});
  const [openEditorTabIndex, setOpenEditorTabIndex] = useState(0);
  const [confirmationDialogIsOpen, setConfirmationDialogIsOpen] = useState(
    false
  );

  const [isEnvSafeToChange, setIsEnvSafeToChange] = useState(true);

  const [deployModalDialogIsOpen, setDeployModalDialogIsOpen] = useState(false);

  // Modal Handlers:
  const {
    isOpen: showTriggerStep,
    onOpen: onTriggerStepOpen,
    onClose: onTriggerStepClose,
  } = useDisclosure();

  const {
    isOpen: isSearchOpen,
    onOpen: onSearchOpen,
    onClose: onSearchClose,
  } = useDisclosure();

  const { isOpen, onOpen, onClose } = useDisclosure();
  const {
    onOpen: onTestDrawerOpen,
    isOpen: isTestDrawerOpen,
    onClose: onTestDrawerClose,
  } = useDisclosure();

  // Editing node
  function addEditingNode(node) {
    const data = {
      flowId: node.flowId,
      nodeId: node.nodeId,
      type: node.type,
      title: node.name,
      description: node.subTitle,
      image: node.image,
      language: node.type === ActionTypes.SAMPLE ? 'json' : 'javascript', // TODO more languages!
      code: node.code,
      order: node.order,
    };

    const key = `${node.nodeId}-${node.type}`;
    const nodeIndex = Object.keys(editingNodes).findIndex((k) => k === key);

    if (editingNodes[key]) {
      setOpenEditorTabIndex(nodeIndex);
    } else {
      setEditingNodes((current) => ({ ...current, [key]: data }));
      setOpenEditorTabIndex(Object.keys(editingNodes).length); // new one will be last in list
    }
  }

  function removeEditingNode(key) {
    const updatedEditing = { ...editingNodes };
    delete updatedEditing[key];
    setEditingNodes(updatedEditing);
  }

  function removeEditingNodesForId(id: string) {
    const updated = Object.entries(editingNodes).reduce((prev, current) => {
      const [key, value] = current;
      const acc = { ...prev };
      // @ts-expect-error TODO - how to TS the reducer?
      if (value.nodeId !== id) {
        acc[key] = value;
      }
      return acc;
    }, {});

    setEditingNodes(updated);
    setOpenEditorTabIndex(Object.keys(updated).length - 1);
  }

  function checkUpdatedNodesForEditor(newNodes) {
    // parse out only relevant nodes
    const editingIds = Object.values(editingNodes).map(({ nodeId }) => nodeId);
    const filtered = newNodes.filter(({ data }) =>
      editingIds.includes(data?.nodeId)
    );

    const updated = {};

    if (filtered.length) {
      // check each incoming update:
      filtered.forEach(({ data }) => {
        if (data) {
          // for each new node, check if anything has changed in the editor:
          const editing = Object.values(editingNodes).filter(
            ({ nodeId }) => nodeId === data.nodeId
          );
          editing.forEach((node) => {
            // @ts-expect-error TODO
            const key = `${node.nodeId}-${node.type}`;
            // @ts-expect-error TODO
            const type = node.type === 'edit' ? 'exec' : node.type;
            updated[key] = {
              // @ts-expect-error TODO
              ...node,
              title: data.text,
              code: data.code[type],
            };
          });
        }
      });
    }

    setEditingNodes({ ...editingNodes, ...updated });
  }

  function closeEditorTabs(
    environment: EnvironmentType,
    callback?: () => void
  ) {
    const action = () => {
      setShowEditor(false);
      clearEditingNodes();
      setCurrentUsersEditing((current: AvatarModel[]) =>
        current.filter((element) => element.id !== currentUser._id)
      );
      callback?.();
      setEnv(environment);
    };

    if (Object.keys(editingNodes).length <= 0 || isEnvSafeToChange) {
      action();
    } else if (showEditor) {
      setAlertDialog({
        title: 'Close editor',
        description:
          'You have changes pending. Closing will lose all changes. Are you sure you want to close?',
        cancelText: 'Cancel',
        confirmText: 'Close anyway',
        destructiveOnClose: () => {
          action();
        },
        harmlessOnClose: () => setConfirmationDialogIsOpen(false),
      } as AlertDialog);
      setConfirmationDialogIsOpen(true);
    }
  }

  function setEnvironment(environment: EnvironmentType) {
    if (environment === env) {
      return;
    }
    setEditable(environment === EnvironmentType.DEVELOPMENT);
    closeEditorTabs(environment);
  }

  function clearEditingNodes() {
    setEditingNodes({});
  }

  return (
    <BuilderContext.Provider
      value={{
        addEditingNode,
        editingNodes,
        checkUpdatedNodesForEditor,
        flow,
        setFlow,
        showEditor,
        elements,
        setShowEditor,
        setSelectedStep,
        selectedStep,
        setEditingNodes,
        removeEditingNode,
        removeEditingNodesForId,
        clearEditingNodes,
        setElements,
        setShowSecondStepModal,
        currentLogicFlow,
        editable,
        setEditable,
        env,
        setEnv: setEnvironment,
        setCurrentLogicFlow,
        currentUsersEditing,
        setCurrentUsersEditing,
        currentUser,
        setCurrentUser,
        isEnvSafeToChange,
        setIsEnvSafeToChange,
        closeEditorTabs,
        // Modals
        showTriggerStep,
        onTriggerStepOpen,
        onTriggerStepClose,
        isTestDrawerOpen,
        onTestDrawerOpen,
        onTestDrawerClose,
        isSearchOpen,
        onSearchOpen,
        onSearchClose,
        isOpen,
        onOpen,
        onClose,
        openEditorTabIndex,
        setOpenEditorTabIndex,
        showSecondStepModal,
        confirmationDialogIsOpen,
        setConfirmationDialogIsOpen,
        alertDialog,
        setAlertDialog,
        deployModalDialogIsOpen,
        setDeployModalDialogIsOpen,
      }}
    >
      {children}
    </BuilderContext.Provider>
  );
}

export function useBuilderContext() {
  return useContext(BuilderContext);
}

export default BuilderContextProvider;
